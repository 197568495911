import React from 'react'

function TimelineOne() {
  return (
    <div className='w-full text-center mt-8'>
      <p className='text-sm font-bold'>EXPLORE THE VERCEL WAY</p>
      <div className='w-full  flex flex-col justify-center mt-8 items-center'>
        <div className='w-px bg-cyan-300 h-24 relative'>
          <div className='absolute w-full h-full bg-gradient-to-b from-black to-transparent'></div>
        </div>
        <div className='w-10 h-10 rounded-full bg-gradient-to-r from-sky-600 to-cyan-300 flex justify-center items-center text-black font-bold text-lg'>1</div>
      </div>
      <div className='text-bg-blue font-bold text-3xl mt-4'>Develop</div>
      <div className='my-3'>
        <p className='font-bold text-3xl md:text-6xl px-4 md:px-0'>Start with the developer</p>
      </div>
      <div className='flex gap-8 w-full justify-center my-10 text-gray-500 text-xs md:text-base md:text-opacity-100 text-opacity-30'>
        <p className='w-4/12 text-left'>Developers love Next.js, the open source React framework Vercel built together with Google and Facebook. Next.js powers the biggest websites like Patreon, for use cases in e-commerce, travel, news, and marketing.</p>
        <p className='w-4/12 text-left'>Vercel is the best place to deploy any frontend app. Start by deploying with zero configuration to our global edge network. Scale dynamically to millions of pages without breaking a sweat.</p>
      </div>
    </div>
  )
}

export default TimelineOne;