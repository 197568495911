import React from 'react'
import Miscellaneous from '../Home/Miscellaneous';
import LandingQuote from './components/LandingQuote';
import PopularitySaas from './components/PopularitySaas';

function Saas() {
  return (
    <div className='w-full bg-black text-white mt-32'>
      <LandingQuote />
      <PopularitySaas />
      {/* <Miscellaneous /> */}
    </div>
  )
}

export default Saas;