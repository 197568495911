import React from 'react'
import AppleWindow from './components/AppleWindow';
import TimelineThreeStage from './components/TimelineThreeStage';
import user1 from "../../images/user1.webp";
import user2 from "../../images/user2.webp";
import user3 from "../../images/user3.webp";
import ChatBox from './components/ChatBox';
import useWindowSize from '../../hooks/useWindowSize';

function TimelineThree() {
    const [width] = useWindowSize();
    return (
        <>
            <TimelineThreeStage
                first={true}
                heading="Push to deploy"
                content="Import your repo with one click, then push. Our built-in CI/CD system triggers for every code change."
                leftContent={
                    <AppleWindow extraStyle={"h-56 md:w-5/12 m-0 w-full"} name="bash">
                        <div className='p-2 code-font'>
                            <div className='flex gap-1 text-xs md:text-base'>
                                <p className='text-gray-500 pr-2 '>▲ ~ e-commerce-site/</p>  git push
                                <div className="w-3 bg-pink-600">&nbsp;</div>
                            </div>
                        </div>
                    </AppleWindow>
                }
            />
            <TimelineThreeStage
                heading="Get your Preview URL"
                content="Every Git branch and PR receives a live, production-like URL that everyone on your team can visit."
                leftContent={
                    <AppleWindow extraStyle={"md:w-5/12 w-full m-0 bg-gray-800 bg-opacity-50 h-auto"} name="vercel">
                        <div className='md:m-4 m-2 git-window' >
                            <div className='flex gap-1'>
                                <p>The latest updates on your project. </p>
                                <div className='flex gap-1 font-thin'>Learn more about <p className='text-blue-500'> Vercel for Git <span>&#x2197;</span></p></div>
                            </div>
                            <div className='w-full'>
                                <table className='w-full text-left my-4 -z-10'>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Status</th>
                                            <th>Preview</th>
                                            <th>Updated</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>front</td>
                                            <td className='flex'>✅ Ready{width > 610 && <pre className='text-blue-500'> (inspect)</pre>}</td>
                                            <td><p className='text-blue-500'>Visit Preview</p></td>
                                            <td>April 15, 2022 at 5:10PM (UTC)</td>
                                        </tr>
                                        <tr>
                                            <td>next-site</td>
                                            <td className='flex'>✅ Ready{width > 610 && <pre className='text-blue-500'> (inspect)</pre>}</td>
                                            <td><p className='text-blue-500'>Visit Preview</p></td>
                                            <td>April 15, 2022 at 5:10PM (UTC)</td>
                                        </tr>
                                        <tr>
                                            <td>front</td>
                                            <td className='flex'>⬅️ Building{width > 610 && <pre className='text-blue-500'> (inspect)</pre>}</td>
                                            <td><p className='text-blue-500'></p></td>
                                            <td>April 15, 2022 at 5:10PM (UTC)</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </AppleWindow>
                }
            />
            <TimelineThreeStage
                heading="Share and Collaborate"
                content="Avoid surprises by iterating with your entire team. Test from the perspective of your users, on every platform."
                last={true}
                leftContent={
                    <div className='flex flex-col md:w-5/12 w-full text-sm gap-4'>
                        <ChatBox userImg={user1} userName={"Sarah (Engineering Team)"} boxColor="bg-blue-600">
                            Here’s my deploy preview for the page update: <p className='font-bold'>https://shop-git-new-checkout.vercel.app</p>
                        </ChatBox>
                        <ChatBox userImg={user2} userName="John (Design Team)" boxColor={"bg-blue-600"}>
                            I love it. Can you double check we’re using the correct brand color scheme?
                        </ChatBox>
                        <ChatBox userImg={user3} userName="Leo (Marketing Team)" boxColor={"bg-green-500"}>
                            Everything looks good to me, ship it!
                        </ChatBox>
                    </div>
                }
            />
            <div className='w-full h-full flex flex-col items-center'>
                <div className='flex flex-col items-center'>
                    <div className="w-px h-28 bg-orange-500 relative">
                        {
                            width < 640 ?

                                <div className="w-full h-full absolute bg-gradient-to-b from-black to-transparent">&nbsp;</div> :
                                <div className="w-full h-full absolute bg-gradient-to-b from-yellow-500 to-transparent">&nbsp;</div>
                        }
                    </div>
                    <div className='w-10 h-10 rounded-full bg-gradient-to-r from-orange-500 to-yellow-500 flex justify-center items-center text-black font-bold text-lg'>3</div>
                    <div className='text-bg-orange font-extrabold text-3xl mt-5'>Ship</div>
                </div>
                <div className='my-10 w-full flex flex-col items-center'>
                    <h1 className='md:text-6xl text-3xl font-bold mb-16'>Delight every visitor</h1>
                    <div className='w-8/12 flex text-gray-500 gap-10 md:text-base text-xs'>
                        <div className='w-2/4'>
                            Merged changes instantly go live on our global edge network, putting your content as close as possible to your customers. Everything is taken care of for you, from SSL encryption to asset compression and cache invalidation.
                        </div>
                        <div className='w-2/4'>
                            Speed is critical to customers - and for SEO. Vercel goes beyond just caching static content, scaling to millions of pages through dynamic code execution.
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TimelineThree;