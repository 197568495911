import React from 'react'
import AppleWindow from './components/AppleWindow'
import CodeLine from './components/CodeLine'
import plantPot from "../../images/plantpot.png"

function LandingThree() {
    const codeData = [
        "export default function({ data }){",
        "\t return <Layout>",
        "\t \t<Product details={data}/>",
        "   </Layout>",
        "}"
    ]
    return (
        <div className='flex justify-center w-full flex-col md:flex-row'>
            <div className='w-8/12 md:ml-24 md:w-5/12 justify-center ml-10'>
                <AppleWindow name={"index.js"} isCode={true} extraStyle={"h-64 md:w-4/5 w-full"}>
                    {
                        codeData.map((code, index) => {
                            return <CodeLine code={code} lineNumber={index + 1} key={index} />
                        })
                    }
                </AppleWindow>
                <AppleWindow name={"localhost:3000"} isCode={false} extraStyle={"translate-x-10 -translate-y-24 md:w-4/5 w-full"}>
                    <div className='m-5 p-2'>
                        <div className='flex items-center justify-between'>
                            <div className='flex items-center'>
                                <div className='w-8 h-8 bg-white rounded-full'>&nbsp;</div>
                                <div className='flex flex-col gap-2'>
                                    <div className='w-11 h-1.5 mx-3 bg-white rounded-md'>&nbsp;</div>
                                    <div className='w-16 h-1.5 mx-3 bg-white bg-opacity-30 rounded-md'>&nbsp;</div>
                                </div>
                            </div>
                            <div className='flex gap-3 w-6/12'>
                                <div className="w-3/12 bg-white bg-opacity-20 h-2 rounded-md">&nbsp;</div>
                                <div className="w-3/12 bg-white bg-opacity-20 h-2 rounded-md">&nbsp;</div>
                                <div className="w-3/12 bg-white bg-opacity-20 h-2 rounded-md">&nbsp;</div>
                                <div className="w-3/12 bg-white bg-opacity-20 h-2 rounded-md">&nbsp;</div>
                            </div>
                        </div>
                        <div className='mt-3 flex justify-between'>
                            <div className='w-5/12 aspect-square rounded-md overflow-hidden '>
                                <img src={plantPot} alt={"plant"} className="w-full h-full object-cover"></img>
                            </div>
                            <div className='w-2/4 flex flex-col justify-between'>
                                <div className='w-full h-full flex items-center justify-between'>
                                    <div className="w-2/4 h-8 bg-white bg-opacity-20 rounded-md">&nbsp;</div>
                                    <div className='px-2 bg-blue-600 text-center rounded-md'>$$$</div>
                                </div>
                                <div className="w-full h-full bg-white bg-opacity-20 rounded-md">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </AppleWindow>
            </div>
            <div className='w-full md:w-5/12 flex justify-center items-start md:flex-col text-xs md:text-base gap-6'>
                <div className='mb-14 flex flex-col justify-center items-center md:justify-start md:items-start w-3/12 md:w-auto'>
                    <h1 className='md:text-2xl w-full text-left font-bold '>Fast Refresh</h1>
                    <p className='md:w-3/4 text-gray-500 md:text-left  my-1'>Reliable live-editing experience for your UI components</p>
                </div>
                <div className='mb-14 flex flex-col justify-center items-center md:justify-start md:items-start w-3/12 md:w-auto'>
                    <h1 className='md:text-2xl w-full text-left font-bold '>Flexible Data Fetching</h1>
                    <p className='md:w-3/4 text-gray-500 md:text-left  my-1'>Connect your pages to any data source, headless CMS, or API and make it work in everyone’s dev environment.</p>
                </div>
                <div className='mb-14 flex flex-col justify-center items-center md:justify-start md:items-start w-3/12 md:w-auto'>
                    <h1 className='md:text-2xl w-full text-left font-bold '>Edge on Localhost</h1>
                    <p className='md:w-3/4 text-gray-500 md:text-left  my-1'>From caching to Serverless Functions, all our cloud primitives work perfectly on localhost.</p>
                </div>
            </div>
        </div>
    )
}

export default LandingThree