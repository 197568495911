import { FiTrendingUp } from 'react-icons/fi';
import { PiGearFineFill } from 'react-icons/pi';
import { FaSitemap } from 'react-icons/fa';
import { RiCustomerServiceFill } from 'react-icons/ri';
import { SiExoscale } from 'react-icons/si';
import { FaUserFriends } from 'react-icons/fa';

const WebDevData = [
  {
    id: 1,
    title: 'Here are some reasons to opt for TTC\'s Services',
    subTitles: [
      {
        title: 'Security Audits',
        description: 'Protect your website and your users\' data with our comprehensive security audits. We identify potential vulnerabilities and implement measures to keep your website safe from cyber threats.'
      },
      {
        title: 'Maintenance and Support',
        description: 'We don\'t just build websites; we ensure they continue to perform optimally. Our team provides ongoing maintenance and support services, keeping your website secure, up-to-date, and running smoothly.',
      },
      {
        title: 'Performance optimization',
        description: 'Slow websites lead to frustrated visitors and lost opportunities. We optimize your website\'s speed and performance, enhancing user experience and search engine rankings.',
      },
      {
        title: 'Search Engine Optimization',
        description: 'Our SEO experts implement strategies to improve your website\'s visibility and ranking on search engines, driving organic traffic and attracting potential customers.',
      },
      {
        title: 'Responsive web designs',
        description: 'In the mobile-centric world, we ensure your website looks and functions flawlessly across all screen sizes. Our responsive web design approach guarantees that your visitors have an exceptional experience, no matter the device they use.',
      },
      {
        title: 'E-commerce solutions',
        description: 'For businesses looking to sell online, our e-commerce solutions offer seamless user experiences, secure payment gateways, inventory management, and analytics to help you optimize your online store\'s performance.',
      },
      {
        title: 'Tailor-made websites',
        description: 'We believe in uniqueness and strive to create tailor-made websites that reflect your brand identity. Whether you need a stunning portfolio site, a robust e-commerce platform, or a dynamic corporate website, our team has the expertise to build it from the ground up.',
      },
    ],
  },
  {
    id: 2,
    title: 'How can TTC contribute to more user interaction on your website?',
    subTitles: [
      {
        title: "We promise 99.999% uptime.",
        description: "We understand that your website is the face of your business. We ensure that it is always up and running, so you never miss out on potential customers.",
        logo: FiTrendingUp
      },

      {
        title: "We offer a blend of technologies",
        description: "Our team of experts has extensive experience in a wide range of technologies, including Python, JavaScript, HTML, CSS, and more. We use the best tools and technologies to build websites that are fast, secure, and scalable.",
        logo: PiGearFineFill
      },

      {
        title: "We have automated sitemaps generation",
        description: "We use automated sitemap generation tools to ensure that your website is always up-to-date. This helps search engines crawl your website more efficiently, improving your search engine rankings.",
        logo: FaSitemap
      },

      {
        title: "We assist you post-development too",
        description: "We don't just build websites; we ensure they continue to perform optimally. Our team provides ongoing maintenance and support services, keeping your website secure, up-to-date, and running smoothly.",
        logo: RiCustomerServiceFill
      },

      {
        title: "We create highly scalable yet responsive websites",
        description: "We use automated sitemap generation tools to ensure that your website is always up-to-date. This helps search engines crawl your website more efficiently, improving your search engine rankings.",
        logo: SiExoscale
      },

      {
        title: "We belive in user-based approach ",
        description: "We don't just build websites; we ensure they continue to perform optimally. Our team provides ongoing maintenance and support services, keeping your website secure, up-to-date, and running smoothly.",
        logo: FaUserFriends
      },
    ],
  }
]

export default WebDevData;