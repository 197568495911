import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useWindowSize from "../hooks/useWindowSize";
import hamburger from "../images/hamburger.png";
import ttcLogo from "../images/ttclogo.png";
import dropdown from "../images/dropdown.svg";
import webdevlogo from "../images/webdevlogo.png";
import mobilelogo from "../images/mobilelogo.png";
import saaslogo from "../images/saaslogo.png";
import consultancylogo from "../images/consultancylogo.png";
import { BiChevronDown } from "react-icons/bi";
import NavbarData from "../constants/NavbarData";

const SubNavItem = ({
  logo,
  title,
  description,
  route,
  setMenuOpen,
  setSubNavMenu,
}) => {
  const navigation = useNavigate();
  const goToWebPage = (route) => {
    window.scrollTo(0, 0);
    setMenuOpen(false);
    setSubNavMenu(false);
    navigation(route);
  };
  return (
    <div
      className="flex items-center lg:gap-5 md:gap-3 justify-center cursor-pointer hover:scale-105 transition-all duration-300"
      onClick={() => goToWebPage(route)}
    >
      <div className="w-12 flex items-center justify-center aspect-square overflow-hidden ">
        <img src={logo} alt="service logo" className="w-2/3 object-cover"></img>
      </div>
      <div className="flex flex-col text-left gap-1">
        <p>{title}</p>
        {/* <p className='text-sm text-gray-500'>Some description of the service</p> */}
      </div>
    </div>
  );
};

const SubNavWithMenu = ({ item, setMenuOpen }) => {
  const [subNavMenu, setSubNavMenu] = useState(false);
  return (
    <li className="text-gray-00">
      <div className="flex items-center">
        <button
          className={`hover:text-white hover:cursor-pointer ${subNavMenu ? "text-white" : "text-gray-500"
            } flex items-center`}
          onClick={() => setSubNavMenu((prevValue) => !prevValue)}
          onBlur={() => setSubNavMenu(false)}
        >
          {item.title}
        </button>
        <BiChevronDown
          size={16}
          className={`transition-all duration-300 ${subNavMenu ? "rotate-180 opacity-100" : "rotate-0 opacity-50"
            }`}
        />
      </div>
      <div
        className={`absolute w-full left-0 bg-black flex justify-center items-center  flex-col transition-all duration-200 ease-in-out -z-10 translate-y-5 overflow-hidden ${subNavMenu
          ? "max-h-40 py-6 border border-gray-500 border-l-0 border-r-0"
          : "max-h-0"
          }`}
      >
        <div className="lg:w-full md:w-5/6 scale-75 md:scale-100 flex justify-center gap-3">
          {item.subNav.map((item, index) => {
            return (
              <SubNavItem
                key={index}
                logo={item.logo}
                title={item.title}
                description={item.description}
                route={item.route}
                setMenuOpen={setMenuOpen}
                setSubNavMenu={setSubNavMenu}
              />
            );
          })}
        </div>
      </div>
    </li>
  );
};

const SubNavWithMenuMobile = ({ item, setMenuOpen }) => {
  const [subNavMenu, setSubNavMenu] = useState(false);
  const navigation = useNavigate();
  const goToWebPage = (route) => {
    window.scrollTo(0, 0);
    setMenuOpen(false);
    setSubNavMenu(false);
    navigation(route);
  };
  return (
    <li className="text-white hover:cursor-pointer flex flex-col justify-center">
      <button
        className={`hover:opacity-100 hover:cursor-pointer flex gap-1 items-center text-center justify-center`}
        onClick={() => setSubNavMenu((prevValue) => !prevValue)}
        onBlur={() => setSubNavMenu(false)}
      >
        {item.title}
        <img
          src={dropdown}
          alt="dropdown"
          className={`w-3 transition-all duration-300 ${subNavMenu ? "rotate-180" : "rotate-0"
            }`}
        ></img>
      </button>
      <div
        className={`text-center transition-all duration-300 overflow-hidden flex flex-col gap-3  ${subNavMenu ? "max-h-64 mt-3" : "max-h-0"
          }`}
      >
        {item.subNav.map((subNavItem, index) => {
          return (
            <div
              className="my-1 bg-white py-1 px-2 bg-opacity-20 rounded-md"
              onClick={() => goToWebPage(subNavItem.route)}
            >
              {subNavItem.title}
            </div>
          );
        })}
      </div>
    </li>
  );
};

function NavBar() {
  const [width] = useWindowSize();
  const [menuOpen, setMenuOpen] = useState(false);
  const navigation = useNavigate();
  const goToWebPage = (route) => {
    window.scrollTo(0, 0);
    setMenuOpen(false);
    navigation(route);
  };
  return (
    <nav className="fixed flex w-full justify-between gap-4 md:px-0 sm:justify-evenly items-center text-md top-0 pb-3 pt-6 bg-transparent z-10 px-2">
      <div
        className="absolute w-full h-full bg-black bg-opacity-40 blur-sm backdrop-blur-md top-0"
        style={{ zIndex: -1 }}
      ></div>
      <div
        className="flex gap-1 items-center justify-start md:w-1/5 sm:w-auto"
        onClick={() => goToWebPage("/")}
      >
        <img
          src={ttcLogo}
          alt={"ttc logo"}
          className="lg:w-5/12 w-32 sm:w-24"
        ></img>
      </div>
      {width < 640 ? (
        <div
          className="relative m-0"
          onClick={() => setMenuOpen((prevValue) => !prevValue)}
        >
          <img src={hamburger} alt={"hamburger"} className="w-8"></img>
        </div>
      ) : (
        <div className="md:w-1/5 md:text-base">
          <ul className="flex justify-center gap-6 lg:text-base  sm:text-xs whitespace-nowrap">
            {NavbarData.map((item, index) => {
              if (item.subNav) {
                return (
                  <SubNavWithMenu
                    key={index}
                    item={item}
                    setMenuOpen={setMenuOpen}
                  />
                );
              }
              return (
                <Link to={item.route} key={index}>
                  <li className="text-gray-500 hover:text-white hover:cursor-pointer">
                    {item.title}
                  </li>
                </Link>
              );
            })}
          </ul>
        </div>
      )}
      {menuOpen && width < 640 && (
        <div className="absolute w-full top-20 bg-transparent left-0  justify-center flex  ">
          <div
            className="absolute w-full h-full bg-black bg-opacity-40 blur-sm backdrop-blur-sm top-0"
            style={{ zIndex: -1 }}
          ></div>
          <ul className="flex flex-col justify-center gap-4 lg:text-base  sm:text-xs whitespace-nowrap items-center py-10">
            {NavbarData.map((item, index) => {
              if (item.subNav) {
                return (
                  <SubNavWithMenuMobile
                    key={index}
                    item={item}
                    setMenuOpen={setMenuOpen}
                  />
                );
              }
              return (
                <Link to={item.route} key={index}>
                  <li className="text-white hover:cursor-pointer">
                    {item.title}
                  </li>
                </Link>
              );
            })}
          </ul>
        </div>
      )}
      {width > 640 && (
        <div className="flex items-center gap-4 md:w-1/5 justify-end md:text-xs sm:text-xs lg:text-base whitespace-nowrap sm:w-auto">
          <div className="py-1 px-4 border border-gray-500 flex text-black items-center justify-between gap-1 rounded-md bg-white hover:bg-black hover:text-white hover:cursor-pointer text-xs">
            Status:
            <div className="w-3 h-3 bg-blue-500 rounded-full">&nbsp;</div>
          </div>
        </div>
      )}
    </nav>
  );
}

export default NavBar;
