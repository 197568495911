import "./App.css";
import MainApplication from "./components/MainApplication";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ContactUs from "./components/ContactUs/ContactUs";
import NavBar from "./components/NavBar";
import AboutUs from "./components/AboutUs/AboutUs";
import WebDev from "./components/WebDev/WebDev";
import MobileApp from "./components/MobileApp/MobileApp";
import EnterpriseConsultancy from "./components/EnterpriseConsultancy/EnterpriseConsultancy";
import Saas from "./components/Saas/Saas";
import NotFoundPage from "./components/NotFoundPage";
import Miscellaneous from "./components/Home/Miscellaneous";
import Saksham from "./components/Saksham";

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <Routes>
        <Route path="/" element={<MainApplication />} loader={() => document.getElementById('root').scrollTo(0, 0)} />
        <Route path="/contact-us" element={<ContactUs />} loader={() => document.getElementById('root').scrollTo(0, 0)} />
        <Route path="/about-us" element={<AboutUs />} loader={() => document.getElementById('root').scrollTo(0, 0)} />
        <Route path="/web-dev" element={<WebDev />} loader={() => document.getElementById('root').scrollTo(0, 0)} />
        <Route path="/mobile-app" element={<MobileApp />} loader={() => document.getElementById('root').scrollTo(0, 0)} />
        <Route path="/enterprise-consultancy" element={<EnterpriseConsultancy />} loader={() => document.getElementById('root').scrollTo(0, 0)} />
        <Route path="/saas" element={<Saas />} loader={() => document.getElementById('root').scrollTo(0, 0)} />
        <Route path="/saksham" Component={() => {
          window.location.href = "https://saksham.ttcindia.co";
          return null;
        }} loader={() => document.getElementById('root').scrollTo(0, 0)} />
        <Route path="*" element={<NotFoundPage />} loader={() => document.getElementById('root').scrollTo(0, 0)} />
      </Routes>
      <Miscellaneous />
    </BrowserRouter>
  );
}

export default App;
