import React from 'react'

function ChatBox(props) {
    return (
        <div className='flex justify-end w-full gap-4 md:text-base text-sm'>
            <div className='flex flex-col'>
                <div className={`${props.boxColor} rounded-xl p-4 font-thin'`}>
                    {
                        props.children
                    }
                </div>
                <div className=' text-right text-sm text-gray-400 p-2 font-thin'>{props.userName}</div>
            </div>
            <div className='w-10 h-10 flex items-center justify-center mt-5'>
                <img src={props.userImg} alt="" className='w-full h-full'></img>
            </div>
        </div>
    )
}

export default ChatBox;