import React from 'react'
import globe from "../../images/globe.png";

function GlobalPresence() {
    return (
        <div className='flex flex-col w-full items-center'>
            <div className='w-8/12'>
                <img src={globe} alt="globe"></img>
            </div>
            <div className='flex w-full bg-white bg-opacity-5 md:p-7 p-2 justify-center sm:gap-5 gap-2 lg:gap-20 md:gap-10 border-t-2 border-b-2 border-white border-opacity-10'>
                <div className='flex flex-col justify-center items-center'>
                    <p className='lg:text-6xl md:text-4xl text-xl font-bold'>90</p>
                    <p className='text-gray-500 md:text-base whitespace-nowrap' style={{ fontSize: "8px" }}>CITIES</p>
                </div>
                <div className="w-px h-20 bg-white bg-opacity-20">&nbsp;</div>
                <div className='flex flex-col justify-center items-center'>
                    <p className='lg:text-6xl md:text-4xl text-xl font-bold'>25B+</p>
                    <p className='text-gray-500 md:text-base whitespace-nowrap' style={{ fontSize: "8px" }}>REQUESTS PER WEEK</p>
                </div>
                <div className="w-px h-20 bg-white bg-opacity-20">&nbsp;</div>
                <div className='flex flex-col justify-center items-center'>
                    <p className='lg:text-6xl md:text-4xl text-xl font-bold'>10PB</p>
                    <p className='text-gray-500 md:text-base whitespace-nowrap' style={{ fontSize: "8px" }}>DATA SERVED</p>
                </div>
                <div className="w-px h-20 bg-white bg-opacity-20">&nbsp;</div>
                <div className='flex flex-col justify-center items-center'>
                    <p className='lg:text-6xl md:text-4xl text-xl font-bold'>99.99%</p>
                    <p className='text-gray-500 md:text-base whitespace-nowrap' style={{ fontSize: "8px" }}>GUARANTEED UPTIME</p>
                </div>
            </div>
        </div>
    )
}

export default GlobalPresence;