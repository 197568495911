import React, { useEffect } from 'react'
import GlobalPresence from './Home/GlobalPresence';
import LandingOne from './Home/LandingOne';
import LandingThree from './Home/LandingThree';
import Miscellaneous from './Home/Miscellaneous';
import TimelineOne from './Home/TimelineOne';
import TimelineThree from './Home/TimelineThree';
import TimelineTwo from './Home/TimelineTwo';


function MainApplication() {
  useEffect(() => {
    console.log("title changed");
    document.title = "TTC India - Home"
  }, []);
  return (
    <div className='w-full bg-black text-white'>
      <LandingOne />
      <TimelineOne />
      <LandingThree />
      <TimelineTwo />
      <TimelineThree />
      <GlobalPresence />
      {/* <Miscellaneous /> */}
    </div>
  )
}

export default MainApplication;