import React from 'react';
import useWindowSize from '../../../hooks/useWindowSize';

function TimelineThreeStage(props) {
    const [width, height] = useWindowSize();
    return (
        <div className='flex flex-col items-center justify-center md:h-96'>
            <div className='w-9/12 flex md:flex-row flex-col justify-between h-full items-center'>
                {
                    props.leftContent
                }
                <div className='md:flex flex-col items-center mx-8 h-full hidden'>
                    {
                        props.first ?
                            <div className='w-px h-2/4 relative bg-slate-600'>
                                <div className="absolute w-full h-full bg-gradient-to-b from-black to-transparent">&nbsp;</div>
                            </div>
                            :
                            <div className="border-l-2 border-dotted border-gray-700 w-0 h-2/4">&nbsp;</div>
                    }
                    <div className="w-2.5 h-2.5 border-2 border-solid border-gray-700 rounded-full">&nbsp;</div>
                    {
                        props.last ?
                            <div className='w-px h-full bg-yellow-500 relative'>
                                <div className='absolute bg-gradient-to-b from-gray-700 to-transparent w-full h-full'></div>
                            </div>
                            : <div className="border-l-2 border-dotted border-gray-700 w-0 h-2/4">&nbsp;</div>
                    }
                </div>
                <div className='flex flex-col justify-center md:w-5/12 w-full md:text-left text-center my-8 md:mb-0'>
                    <h1 className='text-2xl font-bold'>{props.heading}</h1>
                    <p className='text-gray-500 mt-3'>{props.content}</p>
                </div>
            </div>
        </div>
    )
}

export default TimelineThreeStage;