import webdevLogo from '../images/webdevlogo.png';
import mobileLogo from '../images/mobilelogo.png';
import consultancyLogo from '../images/consultancylogo.png';
import schoolLogo from '../images/school.svg';

const NavbarData = [
  {
    title: "Home",
    route: "/",
  },
  {
    title: "Services",
    route: "/services",
    subNav: [
      {
        title: "Web Development",
        route: "/web-dev",
        logo: webdevLogo,
      },
      {
        title: "Mobile Development",
        route: "/mobile-dev",
        logo: mobileLogo,
      },
      {
        title: "Enterprise Consultancy",
        route: "/enterprise-consultancy",
        logo: consultancyLogo,
      },
    ],
  },
  {
    title: "Products",
    route: "/products",
    subNav: [
      {
        title: 'SAKSHAM',
        route: '/saksham',
        logo: schoolLogo,
      }
    ],
  },
  {
    title: "About Us",
    route: "/about-us",
  },
  {
    title: "Contact Us",
    route: "/contact-us",
  },
];

export default NavbarData;
