import React, { useEffect } from 'react'
import TeamMember from './components/TeamMember';
import gatsby from "../../images/gatsby.png";
import useWindowSize from "../../hooks/useWindowSize";
import Partners from './components/Partners';
import TeamMembersContainer from './components/TeamMembersContainer';

function AboutUs() {
    const [width] = useWindowSize();
    useEffect(() => {
        document.title = "TTC India - About Us"
    }, [])
    return (
        <div className='mt-32 w-full bg-black text-white'>
            <div className='flex flex-col justify-center items-center mt-20  mb-20'>
                {
                    width > 1024 &&
                    <div className="w-full bg-white h-0.5 mb-32 bg-opacity-20 relative flex items-center">
                        <div className="absolute w-full h-full bg-gradient-to-r from-black via-transparent to-transparent">&nbsp;</div>
                        <div className="absolute w-full h-full bg-gradient-to-l from-black via-transparent to-transparent">&nbsp;</div>
                        <div className="w-3 h-3 rounded-full border-2 border-white about-us-animation bg-black border-opacity-30">&nbsp;</div>
                    </div>
                }
                <h1 className='text-center lg:text-4xl text-2xl font-bold opacity-80'>About Us</h1>
                <p className='mt-16 text-center w-7/12 text-gray-400 md:text-base text-xs'>Operating out of <b>Surat, Ahmedabad, Bengaluru and Mumbai</b>, we are a company inspired by the impact that <b>technology</b> has on the lives of people all over the world. It was started by <b>Parjanya Modi</b>, with the idea of making technology <b>accessible and affordable</b> to small businesses and yet being scalable for larger enterprises. In fact our principle has always been to support businesses throughout their journey of <b>growth</b> enabling them to materialise the enterprise of their dreams.</p>
                {
                    width > 1024 &&
                    <div className="w-full bg-white h-0.5 mt-32 bg-opacity-20 relative flex items-center">
                        <div className="absolute w-full h-full bg-gradient-to-r from-black via-transparent to-transparent">&nbsp;</div>
                        <div className="absolute w-full h-full bg-gradient-to-l from-black via-transparent to-transparent">&nbsp;</div>
                        <div className="w-3 h-3 rounded-full border-2 border-white about-us-animation bg-black border-opacity-30">&nbsp;</div>
                    </div>
                }
            </div>
            {
                /*This component is left for adding the team members in the future*/
            }
            {/* <TeamMembersContainer /> */}
            {
                /*this component is left for adding the partners in the future*/
            }
            {/* <Partners /> */}
        </div>
    )
}

export default AboutUs;