import React from 'react'
import cloudlogo from "../../../images/cloud.png";
import gearlogo from "../../../images/gearlogo.png";
import useWindowSize from '../../../hooks/useWindowSize';

function LandingQuote() {
    const [width, height] = useWindowSize();
    return (
        <div className='p-10'>
            <div className='flex md:flex-row flex-col justify-between items-center'>
                <div className='md:ml-20 md:w-5/12 w-8/12 md:mb-0 mb-10'>
                    <h1 className='text-7xl font-bold text-center'>SaaS</h1>
                    <p className=' md:w-full w-full text-lg text-gray-400 italic text-center mt-10'>
                        “Software is the magical thing whose importance only goes up over time. Software as a Service is a part of Cloud Computing, best described as a software delivery model controlled centrally, works on a subscription basis and is a web-hosted software.
                        ”
                    </p>
                </div>
                <div className='relative md:w-4/12 w-6/12 flex items-center justify-center '>
                    <div className='absolute w-full h-full flex items-center justify-center translate-x-5 translate-y-5'>
                        <img src={gearlogo} alt="gear" className=' w-4/12 saas-gear '></img>
                    </div>
                    {
                        width > 600 &&
                        <div className='absolute w-8/12 h-full flex items-center justify-center lg:translate-x-24 translate-x-20 lg:translate-y-16 translate-y-12'>
                            <img src={gearlogo} alt="gear" className=' w-2/12 saas-gear-opp '></img>
                        </div>
                    }
                    <img src={cloudlogo} alt="" className='w-full'></img>
                </div>
            </div>
        </div>
    )
}

export default LandingQuote;