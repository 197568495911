import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';

const getActiveTextClass = (activeText) => {
  switch (activeText) {
    case 0: return "btn-bg-purple";
    case 1: return "btn-bg-blue";
    case 2: return "btn-bg-orange";
    case 3: return "btn-bg-green";
    default: return "";
  }
}

function LandingOne() {
  const [activeText, setActiveText] = useState(0);
  const activeBtnClass = getActiveTextClass(activeText);
  useEffect(() => {
    const timer = setInterval(() => {
      setActiveText((prevValue) => (prevValue + 1) % 4)
    }, 2500);
    return () => clearInterval(timer);
  }, [])

  return (
    <div className='w-full bg-black text-center'>
      <div className='mt-32 xl:mt-36 mb-4 flex flex-col'>
        <h1 className={`md:h-28 h-20 font-bold md:text-8xl text-6xl transition-all ${activeText === 0 ? "text-bg-purple" : ""} `}>Develop.</h1>
        <h1 className={`md:h-28 h-20 font-bold md:text-8xl text-6xl transition-all ${activeText === 1 ? "text-bg-blue" : ""}`}>Customize.</h1>
        <h1 className={`md:h-28 h-20 font-bold md:text-8xl text-6xl transition-all ${activeText === 2 ? "text-bg-orange" : ""}`}>Integrate.</h1>
        <h1 className={`md:h-28 h-20 font-bold md:text-8xl text-6xl transition-all ${activeText === 3 ? "text-bg-green" : ""}`}>Deliver.</h1>
      </div>
      <div className='flex flex-col sm:flex-row gap-8 justify-center whitespace-nowrap w-full items-center'>
        <div className={`rounded-md md:w-2/12 w-5/12 md:text-base text-xs transition-all ${activeBtnClass}`}>
          <Link to={"/contact-us"}>
            <button className='bg-white text-black rounded-md py-3 w-full hover:bg-black hover:text-white'>Get in Touch <span className=' text-lg font-extrabold ml-4 text-inherit'>&rarr;</span></button>
          </Link>
        </div>
      </div>
      <div className='text-xl flex justify-center items-center w-full py-5'>
        <p className='w-3/5 text-gray-500 md:text-base text-sm'>Vercel combines the best developer experience with an obsessive focus on end-user performance.
          Our platform enables frontend teams to do their best work.</p>
      </div>
    </div>
  )
}

export default LandingOne;