import React from 'react';
import saasIllustration from "../../../images/saasillustration2.png";

function PopularitySaas() {
    return (
        <div className='py-10 flex md:flex-row flex-col justify-evenly items-center md:my-20 '>
            <div className='md:w-4/12 w-8/12 md:mb-0 mb-10'>
                <img src={saasIllustration} alt="web3"></img>
            </div>
            <div className='md:w-5/12 w-8/12 flex flex-col gap-5'>
                <p className='text-lg text-gray-400 text-center'>Popularly known as <b>“on-demand”</b> software, <b>SaaS</b> is the best service to subscribe to if your enterprise requires <b>multiple user</b> access, data retrieval points and <b>re-centralised control</b> (combination of centralised and decentralised control). Our <b>SaaS</b> offers a <b>multi-tenant</b> architecture. With this model, a single version of the application, with a single configuration <b>(hardware, network, operating system)</b>, is used for all customers.</p>
            </div>
        </div>
    )
}

export default PopularitySaas;