import React from 'react'
import Miscellaneous from '../Home/Miscellaneous';
import LandingQuote from './components/LandingQuote';

function MobileApp() {
  return (
    <div className='w-full bg-black text-white mt-40'>
      <LandingQuote />
      {/* <Miscellaneous /> */}
    </div>
  )
}

export default MobileApp;