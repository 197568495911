import React from 'react'
import Miscellaneous from '../Home/Miscellaneous';
import LandingQuote from './components/LandingQuote';
import Web3Info from './components/Web3Info';
import WebDevData from '../../constants/WebDevData';
import computerImage from "../../images/computerimage.png";

function WebDev() {
  return (
    <div className='w-full bg-black text-white mt-24'>
      {/* <LandingQuote />
      <Web3Info /> */}
      {
        WebDevData.slice(0, 1).map((data, index) => {
          return (
            <div key={index} className='mb-20 flex flex-col items-center'>
              <p className='lg:text-4xl sm:text-3xl text-2xl  w-4/5 text-center font-bold mb-10'>{data.title}</p>
              <div className='gap-10 grid grid-cols-2 w-4/5 m-auto'>
                {
                  data.subTitles.map((subTitle, index) => {
                    return (
                      <div key={index} className='flex flex-col items-center'>
                        <p className='md:text-xl text-sm w-full mb-4 uppercase'>{subTitle.title}</p>
                        <p className='md:text-base sm:text-sm text-xs md:text-justify font-thin text-gray-400'>{subTitle.description}</p>
                      </div>
                    )
                  })
                }
                {
                  index === 0 &&
                  <div className='flex items-center justify-center'>
                    <img src={computerImage} alt="computer" className='lg:w-1/3 w-2/3' />
                    <p className='absolute lg:text-6xl md:text-4xl sm:text-3xl sm:-translate-y-5 -translate-y-2  font-extrabold'>{"</>"}</p>
                  </div>
                }
              </div>
            </div>
          )
        })
      }
      {
        WebDevData.slice(1, 2).map((data, index) => {
          return (
            <div key={index} className='mb-20 flex flex-col items-center'>
              <p className='lg:text-4xl sm:text-3xl text-2xl w-4/5 text-center font-bold mb-10'>{data.title}</p>
              <div className='lg:gap-10 gap-4 grid grid-cols-2 w-4/5 m-auto'>
                {
                  data.subTitles.map((subTitle, index) => {
                    return (
                      <div key={index} className='flex md:flex-row md:text-left text-center flex-col md:gap-10 gap-4 items-center rounded-md p-4'>
                        <div>
                          <subTitle.logo color='white' size={100} className="text-black" />
                        </div>
                        <div>
                          <p className='lg:text-xl sm:text-base text-sm w-full mb-4 uppercase sm:font-bold sm:text-white text-white/50'>{subTitle.title}</p>
                          <p className='text-justify lg:text-sm text-xs font-thin text-gray-400 mb-4 sm:block hidden'>{subTitle.description}</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          )
        })
      }
    </div>
  )
}

export default WebDev;