import React, { useState } from 'react';

function ContactUsForm() {
  const [form, setForm] = useState({
    name: '',
    email: '',
    contactNumber: '',
    message: ''
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    })
  };

  return (
    <div className='p-5 w-10/12 md:w-9/12 lg:w-4/12 md:p-6 border-2 border-white bg-white/5 border-opacity-10 rounded-md box-border h-full'>
      <h1 className='md:text-xl mb-5 font-bold text-center'>Contact Us</h1>
      <div className='flex flex-col items-center md:p-6 p-5 h-full box-border'>
        {/* <div className='text-sm text-white text-opacity-50 mb-10'>Select a Git provider to import an existing project from a Git Repository.</div> */}
        <div className='flex flex-col w-full gap-6'>
          <input type={"text"} placeholder="Name" className='text-xs md:text-base py-2 px-3 rounded-md bg-white bg-opacity-10 focus:outline-none border-2 border-white border-opacity-20' name='name' onClick={handleChange}></input>
          <input type={"text"} placeholder="Email" className='text-xs md:text-base py-2 px-3 rounded-md bg-white bg-opacity-10 focus:outline-none border-2 border-white border-opacity-20' name='email' onClick={handleChange}></input>
          <input type={"text"} placeholder="Contact Number" className='text-xs md:text-base py-2 px-3 rounded-md bg-white bg-opacity-10 focus:outline-none border-2 border-white border-opacity-20' name='contactNumber' onClick={handleChange}></input>
          <textarea type={"text"} placeholder="Message" className='text-xs md:text-base py-2 px-3 rounded-md bg-white bg-opacity-10 focus:outline-none border-2 border-white border-opacity-20 h-28' name='message' onClick={handleChange}></textarea>
        </div>
        <button className='my-4 py-2 px-5 bg-white rounded-sm text-black font-semibold hover:scale-110 transition-all'>Send</button>
      </div>
    </div>
  )
}

export default ContactUsForm;