import React from 'react';
import ttcLogo from "../../../images/ttclogoplain.png"
import nextjs from "../../../images/nextjs.png";
import nextcommerce from "../../../images/nextcommerce.png";
import sveltekit from "../../../images/sveltekit.png";
import nuxtkit from "../../../images/nuxtkit.png";
// import trusted from "../../../images/trusted companies.png";
function TTCLocation() {
  return (
    <div className='md:p-6 p-4 bg-white bg-opacity-5 border-2 border-white border-opacity-10 rounded-md lg:w-4/12 md:w-9/12 w-10/12 h-full'>
      <h1 className='text-xl mb-2 font-bold'>Find us here</h1>
      <iframe title='TTC Location' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3561.394527603399!2d82.19247981504243!3d26.7955636831781!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399a07baa326b6cf%3A0x94e12b20dfda6ee3!2sShri%20Ram%20Mandir%20Ayodhya!5e0!3m2!1sen!2sin!4v1667197137650!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" className='border-0 w-full aspect-video rounded-md'></iframe>
      <div className='pt-4 flex flex-col gap-2'>
        <div className='flex items-center gap-1'>
          <img src={ttcLogo} alt="logo" className='w-6'></img>
          <h3 className='text-lg font-bold'>Tejas Technological Consultancy</h3>
        </div>
        <div>
          <h4 className='opacity-70'>Shri Ram Mandir Ayodhya</h4>
          <h4 className='opacity-70'>Sai Nagar, Ayodhya, Uttar Pradesh 224123</h4>
        </div>
        <h4 className='opacity-70'>+91-9113812284</h4>
        <h4 className='opacity-70'>contactus@ttcindia.co</h4>
      </div>

    </div>
  )
}

export default TTCLocation;


/*<p className='text-sm text-white text-opacity-50 mb-10'>Alternatively, get started with a template</p>
            <div className='grid grid-cols-2 grid-flow-row gap-3'>
                <div className='border-2 border-black rounded-lg overflow-hidden hover:border-2 hover:border-white hover:box-border'>
                    <img src={nextjs} alt="" className='hover:opacity-50 hover:cursor-pointer'></img>
                </div>
                <div className='border-2 border-black rounded-lg overflow-hidden hover:border-2 hover:border-white hover:box-border'>
                    <img src={nextcommerce} alt="" className='hover:opacity-50 hover:cursor-pointer'></img>
                </div>
                <div className='border-2 border-black rounded-lg overflow-hidden hover:border-2 hover:border-white hover:box-border'>
                    <img src={sveltekit} alt="" className='hover:opacity-50 hover:cursor-pointer'></img>
                </div>
                <div className='border-2 border-black rounded-lg overflow-hidden hover:border-2 hover:border-white hover:box-border'>
                    <img src={nuxtkit} alt="" className='hover:opacity-50 hover:cursor-pointer'></img>
                </div>
            </div>*/