import React from 'react';
import bitbucket from "../../images/bitbucket.png"
import github from "../../images/github.png"
import gitlab from "../../images/gitlab.png";
import nextjs from "../../images/nextjs.png";
import nextcommerce from "../../images/nextcommerce.png";
import sveltekit from "../../images/sveltekit.png";
import nuxtkit from "../../images/nuxtkit.png";
import trusted from "../../images/trusted companies.png";
import ContactUsForm from './components/ContactUsForm';
import TTCLocation from './components/TTCLocation';

function BeginJourney() {
  return (
    <div className='mt-24'>
      <h1 className='font-bold mb-10 text-center text-sm box-border'>BEGIN YOUR JOURNEY WITH US</h1>
      <div className='flex flex-col lg:flex-row justify-center gap-10 items-center lg:h-[500px] xl:h-full'>
        <ContactUsForm />
        <TTCLocation />
      </div>
      <div className='w-full text-center my-20 flex flex-col items-center'>
        <h1 className='font-bold text-sm mb-5'>TRUSTED BY THE BEST FRONTEND TEAMS</h1>
        <img src={trusted} alt="trusted companies" className='w-9/12 '></img>
      </div>
    </div>
  )
}

export default BeginJourney;