import React from 'react'

function AppleWindow(props) {
    return (
        <div className={` bg-black border rounded-md border-gray-700 flex-col overflow-hidden ${props.extraStyle ? props.extraStyle : ""}`}>
            <div className='w-full flex items-baseline border-gray-600 border-b'>
                <div className='flex gap-2 mx-2 my-4'>
                    <div className='w-3 h-3 rounded-full bg-orange-600 '></div>
                    <div className='w-3 h-3 rounded-full bg-yellow-400 '></div>
                    <div className='w-3 h-3 rounded-full bg-green-500 '></div>
                </div>
                <div className=' h-3/4 ml-6 bottom-0 px-3 py-1.5 border border-gray-600 rounded-t-md border-b-black translate-y-2 bg-black'>
                    <p className='text-xs text-gray-500'>{props.name ? props.name : "index.js"}</p>
                </div>
            </div>
            <div className='relative'>
                {
                    props.isCode &&
                    <div className='w-6 h-56 text-center bg-white bg-opacity-10 absolute'>&nbsp;</div>
                }
                {
                    props.children
                }
            </div>
        </div>
    )
}

export default AppleWindow