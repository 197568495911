import React from 'react'

function CodeLine(props) {
    return (
        <div className='flex items-center lg:text-base text-xs'>
            <pre className='px-2 text-gray-600'>{`${props.lineNumber}`}</pre>
            <pre className='ml-1 code-font'>{props.code}</pre>
        </div>
    )
}

export default CodeLine