const FooterData = [
  {
    title: 'Services',
    subTitles: [
      {
        title: 'Web Development',
        route: '/web-dev',
      },
      {
        title: 'Mobile Development',
        route: '/mobile-dev',
      },
      {
        title: 'Enterprise Consultancy',
        route: '/enterprise-consultancy',
      },
    ]
  },
  {
    title: "Company",
    subTitles: [
      {
        title: 'Blog',
        route: '/blog',
      },
      {
        title: 'About Us',
        route: '/about-us',
      },
      {
        title: 'Careers',
        route: '/careers',
      },
      {
        title: 'Contact Us',
        route: '/contact-us',
      }
    ],
  },
  {
    title: "Legal",
    subTitles: [
      {
        title: 'Privacy Policy',
        route: '/privacy-policy',
      },
      {
        title: 'Terms of Service',
        route: '/terms-of-service',
      },
      {
        title: 'Support Terms',
        route: '/support-terms',
      },
      {
        title: "Udhyam Registration",
        route: '/udhyam-registration',
      }
    ],
  }
]

export default FooterData;