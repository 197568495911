import React, { useRef, useState } from 'react';
import next from "../../images/next.png"
import react from "../../images/react.png"
import nuxt from "../../images/nuxt.png"
import vue from "../../images/vue.png"
import ember from "../../images/ember.png"
import svelte from "../../images/svelte.png"
import angular from "../../images/angular.png"
import hugo from "../../images/hugo.png"
import gatsby from "../../images/gatsby.png"
import useWindowSize from '../../hooks/useWindowSize';
import TechnologiesData from '../../constants/TechnologiesData';
import { RxCross2 } from 'react-icons/rx';

function TimelineTwo() {
  const [width] = useWindowSize();
  const [activeTech, setActiveTech] = useState(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const techDescriptionRef = useRef(null);
  const techMenuRef = useRef(null);

  const handleTechClick = (index) => {
    if (index === null) {
      setActiveTech(null);
      techMenuRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    } else {
      setActiveTech(index);
      techDescriptionRef.current.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  return (
    <div className='text-center font-bold flex flex-col items-center'>
      <h1 className=''>WORK WITH 15+ TECHNOLOGIES</h1>
      <div className='flex gap-4 flex-col lg:flex-row my-8 justify-center w-5/6'>
        <div className={`flex flex-wrap gap-5 justify-center  ${activeTech !== null ? 'lg:w-1/2 w-full' : 'w-full'}`} ref={techMenuRef}>
          {
            TechnologiesData.map((tech, index) => {
              return (
                <button key={index} className={`text-sm rounded-md p-2 lg:w-20 sm:w-12 w-8 lg:h-12 sm:h-8 h-4 box-content border-4 hover:scale-110 transition-all ease-in-out duration-500 bg-white ${activeTech === index ? ' border-[#2563eb]' : 'border-white'}`} onClick={() => handleTechClick(index)}>
                  <img src={tech.logo} alt="logo" className='h-full w-full object-contain' />
                </button>
              );
            })
          }
        </div>
        <div className={`${activeTech !== null ? 'lg:w-1/2 w-full p-4 pb-8 lg:h-96' : 'w-0 h-0'} overflow-hidden transition-all duration-1000 flex flex-col gap-4 justify-between items-center  bg-white/20`} ref={techDescriptionRef}>
          {
            activeTech !== null &&
            <div className='w-full flex justify-end'>
              <RxCross2 className='cursor-pointer hover:scale-110 transition-all duration-500 ease-in-out' size={20} onClick={() => handleTechClick(null)} />
            </div>
          }
          <p className='w-4/5 md:text-base sm:text-sm text-xs font-thin sm:font-semibold'>{activeTech !== null && TechnologiesData[activeTech].description}</p>
          <div className='w-1/2 flex justify-center bg-white rounded p-4'>
            <img src={activeTech !== null && TechnologiesData[activeTech].logo} alt="logo" className='w-full lg:h-28 md:h-24 sm:h-16 h-12 object-contain bg-white' />
          </div>
        </div>
      </div>
      <div className='mb-2'>
        <div className='flex flex-col items-center'>
          <div className="w-px h-28 bg-pink-600 relative">
            <div className="w-full h-full absolute bg-gradient-to-b from-black to-transparent">&nbsp;</div>
          </div>
          <div className='w-10 h-10 rounded-full bg-gradient-to-r from-violet-600 to-pink-600 flex justify-center items-center text-black font-bold text-lg'>2</div>
          <div className='text-bg-purple font-extrabold text-3xl mt-4'>Preview</div>
        </div>
      </div>
      <div className='flex flex-col items-center'>
        <h1 className='text-2xl md:text-6xl'>Accelerate with your team</h1>
        <p className='w-2/4 text-center my-10 font-thin text-gray-500 md:text-base text-xs'>Frontend development is not meant to be a solo activity. The Vercel platform makes it a collaborative experience with deploy previews for every code change, by seamlessly integrating with GitHub, GitLab, and Bitbucket.</p>
      </div>
    </div>
  )
}

export default TimelineTwo;