import React, { useEffect } from 'react'
import BeginJourney from './BeginJourney';
import Miscellaneous from "../Home/Miscellaneous";

function ContactUs() {
  useEffect(() => {
    document.title = "TTC India - Contact Us"
  }, []);
  return (
    <div className='w-full bg-black text-white mt-20'>
      <BeginJourney />
      {/* <Miscellaneous /> */}
    </div>
  )
}

export default ContactUs;