import React from 'react';
import mobiletabimage from ".././../../images/mobiletabimage.png";

function LandingQuote() {
    return (
        <div className='flex lg:flex-row flex-col items-center lg:justify-evenly mb-40 gap-10'>
            <div className='lg:w-5/12 w-8/12 mb-10 lg:mb-0'>
                <h1 className='sm:text-7xl text-4xl font-bold lg:text-left text-center '>Mobile-App</h1>
                <h1 className='sm:text-7xl text-4xl font-bold lg:text-left text-center'>Development</h1>
                <div className='w-full flex items-center flex-col'>
                    <p className=' w-9/12 md:text-lg text-gray-400 italic text-center mt-10'>
                        “The rich and interactive experiences we have come to expect on mobile apps have created new standards and expectations for all digital media including the web. The result is websites are evolving to become more app-like in their rich functionality.”
                    </p>
                    <p className='w-10/12 text-right mt-5 md:text-base text-sm'> - Raj Aggarwal, CEO of Localytic</p>
                </div>
            </div>
            <div className='lg:w-3/12 w-6/12'>
                <img src={mobiletabimage} alt="" className='w-full'></img>
            </div>
        </div>
    )
}

export default LandingQuote;