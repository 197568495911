import React from 'react';
import vercelLogo from "../../images/logo.svg";
import github from "../../images/github.png";
import twitter from "../../images/twitter.png";
import useWindowSize from '../../hooks/useWindowSize';
import { useState } from 'react';
import dropdown from "../../images/dropdown.svg";
import ttcLogo from "../../images/ttclogo.png";
import FooterData from '../../constants/FooterData';

const SubFooterMenu = ({ footerItem }) => {
  const [width] = useWindowSize();
  const [menuOpen, setMenuOpen] = useState(false);
  return (
    <div>
      <button className='w-full mb-5 flex justify-between items-center gap-2 sm:bg-transparent bg-white/20 px-3 py-1 sm:p-0 rounded-md ' onClick={() => {
        if (width < 640) {
          setMenuOpen((prevValue) => {
            return !prevValue;
          })
        }
      }} onBlur={() => setMenuOpen(false)}>{footerItem.title} {width <= 640 && <img src={dropdown} alt="" width="10px" style={{ rotate: `${menuOpen ? "180deg" : "0deg"}` }}></img>}</button>
      {
        (width > 640 || menuOpen) &&
        <div className='md:pl-0'>
          {
            footerItem.subTitles.map((item, index) => {
              return (
                <p key={index} className={`font-thin text-gray-400 text-sm mb-3 hover:text-white hover:cursor-pointer ${width <= 640 ? 'pl-3' : ''}`}>{item.title}</p>
              );
            })
          }
        </div>
      }
    </div>
  )
}


function Miscellaneous() {
  return (
    <div className='w-full bg-white bg-opacity-5 p-10 border-t-2 border-white border-opacity-10'>
      <div className='flex sm:flex-row flex-col justify-center w-full lg:gap-36 md:gap-20 sm:gap-10'>
        <div>
          <div className='flex gap-1 items-center justify-center mb-10'>
            <img src={ttcLogo} alt="ttc logo" width="150px"></img>
          </div>
        </div>
        {
          FooterData.map((footerItem, index) => {
            return (
              <div key={index}>
                <SubFooterMenu footerItem={footerItem} />
              </div>
            );
          })
        }
      </div>
      <div className='flex md:flex-row flex-col items-center justify-center gap-10 md:gap-36 text-sm text-gray-500 mt-10 whitespace-nowrap'>
        <div>Copyright © 2022 Vercel Inc. All rights reserved.</div>
        <div className='flex gap-2'>
          <div className='w-6 h-6'>
            <img src={github} alt={"github"} className="opacity-40 hover:opacity-100 hover:cursor-pointer"></img>
          </div>
          <div className="w-px h-full bg-gray-600">&nbsp;</div>
          <div className='w-6 h-6'>
            <img src={twitter} alt="twitter" className='opacity-40 hover:opacity-100 hover:cursor-pointer'></img>
          </div>
        </div>
        <div>
          <div className='py-1 px-2 border border-gray-500 flex text-white items-center justify-between gap-1 rounded-md'>
            Status:
            <div className="w-3 h-3 bg-blue-500 rounded-full">&nbsp;</div>
            <p className='text-blue-500'>All systems normal.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Miscellaneous;