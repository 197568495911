import React, { useEffect } from 'react'
import Error404 from "../images/404.png";
function NotFoundPage() {
    useEffect(() => {
        document.title = "404 Page Not Found.."
    }, []);
    return (
        <div className='mt-32 w-full bg-black text-white flex flex-col items-center'>
            <div className='bg-white w-fit rounded-full border-2'>

                <img src={Error404} alt="404"></img>
            </div>
            <h1 className='text-4xl font-extrabold text-center -translate-y-28'>Page Not Found</h1>
        </div>
    )
}

export default NotFoundPage;